.collection-item {
  display: flex;
  margin-right: 2rem;
  margin-bottom: 5rem;
  flex-direction: row;
  flex: 1;
  color: #fff;

  min-width: 75vw;

  // &[data-is-selected='true'] {
  //   flex-basis: 100%;
  // }

  @media screen and (max-width: 767px) {
    width: 100%;
    flex: 1;
  }

  @media screen and (max-width: 1080px) {
    min-width: 500px;
  }

  @media screen and (min-width: 1081px) {
    min-width: 500px;
  }

  @media screen and (min-width: 1280px) {
    width: 100%;
  }

  @media screen and (min-width: 1440px) {
    width: auto;
    flex-direction: column;
    align-items: flex-start;
    font-size: 15px;
  }

  @media screen and (min-width: 1920px) {
    width: auto;
  }
}

.item-container {
  display: flex;
  width: 100%;
  color: #fff;

  &-open {
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  @media screen and (min-width: 1920px) {
    width: auto;
    align-items: flex-start;
  }
}

.item-number {
  margin-top: 3.5em;
  margin-right: 0.4em;
  margin-bottom: 0px;
  font-family: 'Roboto Mono', sans-serif;
  color: #ffc107;
  font-size: 1em;
  line-height: 1em;
  font-weight: 700;

  @media screen and (max-width: 767px) {
    width: 100%;
    flex: 1;
    margin-bottom: 1em;
  }

  // @media screen and (min-width: 1280px) {
  //   width: 100%;
  // }

  @media screen and (min-width: 1440px) {
    width: auto;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.9em;
  }

  @media screen and (min-width: 1920px) {
    width: auto;
  }
}

.item-container {
  display: flex;
  width: 100%;
  color: #fff;

  &-open {
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  @media screen and (min-width: 1920px) {
    width: auto;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

.item-link {
  position: relative;
  pointer-events: auto;
  display: flex;
  margin-left: 0.5em;
  padding: 2em;
  flex-direction: column;
  align-self: flex-start;
  flex: 1;
  transition: box-shadow 200ms ease, color 200ms ease,
    background-color 200ms ease;
  color: #fff;
  cursor: pointer;

  // Styles from .card-content
  // border-radius: 20px;
  // background: #1c1c1e;
  overflow: hidden;
  width: 100%;
  // height: 250px;
  // height: 100%;
  margin: 0 auto;

  &:hover,
  &[data-is-selected='true'] {
    background-color: #ffc107;
    box-shadow: 0 9px 20px 0 rgba(0, 0, 0, 0.2);
    color: #333;
  }

  &[data-is-selected='true'] .title-wrapper,
  &:hover .title-wrapper {
    border-bottom: 1px solid #333;
  }

  &[data-is-selected='true'] {
    height: auto;
  }

  @media screen and (max-width: 767px) {
    padding: 0.4em 1.2em 1.2em;
  }

  @media screen and (min-width: 1280px) {
    flex-direction: row;
    flex: 1;
    overflow: auto;
  }

  @media screen and (min-width: 1440px) {
    max-width: 500px;
    margin-left: 0.5em;
    padding: 1.7em 1.7em 2em;
    flex-direction: column;
    flex: 0 auto;
  }
}

.content-wrapper {
  display: flex;
  position: relative;
  // z-index: 2;

  margin-bottom: 1rem;
  flex-direction: column;

  overflow: hidden;
  // height: 0;
  // transition: height, opacity 0.2s ease-out;

  // &[data-is-selected='true'] {
  //   height: auto;
  // }

  @media screen and (min-width: 1280px) {
    order: 1;
    flex: 1;
    height: unset;
  }

  @media screen and (min-width: 1440px) {
    order: 0;
  }
}

.double-title-wrapper {
  display: flex;
  margin-bottom: 1em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.title-wrapper {
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-bottom: 1px solid #ffc107;

  @media screen and (min-width: 1280px) {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.state {
  margin-top: 0px;
  margin-right: 0.1em;
  margin-bottom: 0px;
  font-family: 'Source Code Pro', sans-serif;
  font-size: 4.5em;
  line-height: 1.3em;
  font-weight: 200;

  @media screen and (min-width: 1280px) {
    margin-right: 0.1em;
  }
}

.city {
  margin-right: 0.4em;
  margin-bottom: 0px;
  font-family: 'Source Code Pro', sans-serif;
  font-size: 2em;
  line-height: 1em;
  font-weight: 200;

  @media screen and (max-width: 479px) {
    font-size: 1.5em;
  }

  @media screen and (min-width: 1280px) {
    font-size: 2.3em;
  }
}

.description {
  margin-bottom: 0px;
  font-family: Inconsolata, monospace;
  font-size: 1.2em;
  line-height: 1.3em;
  font-weight: 400;
  text-transform: lowercase;
  display: contents;

  padding: 460px 35px 35px 35px;
  max-width: 700px;
  width: 90vw;

  position: relative;
  z-index: 2;
  cursor: default;

  @media screen and (max-width: 479px) {
    font-size: 1em;
  }

  @media screen and (min-width: 1280px) {
    margin-top: 0px;
    font-size: 1.1em;
  }

  @media screen and (min-width: 1440px) {
    font-size: 1.1em;
  }
}

.video-wrapper {
  display: flex;
  opacity: 0;
  height: auto;
  background-color: transparent;
  transition: opacity 0.2s ease-out;

  &[data-is-selected='true'] {
    opacity: 1;
    // height: auto;
  }

  @media screen and (min-width: 1280px) {
    overflow: hidden;
    width: 550px;
    // height: 309px;
    margin-left: 1.5em;
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  @media screen and (min-width: 1440px) {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    margin-left: 0em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
}

.video {
  width: 100% !important;
  height: auto !important;
}

// .open .item-link {
//   height: auto;
//   max-width: 700px;
//   overflow: hidden;
// }

.overlay {
  z-index: 1;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  will-change: opacity;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  // max-width: 990px;

  & a {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100vw;
    left: 50%;

    transform: translateX(-50%);
  }
}

.card-open-link {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.image {
  display: inline-block;
  width: 100%;
}

.share {
  &-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
  }

  &-button {
    position: relative;
    z-index: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 1rem;
    border: 0px solid #333;
    background-color: #ffc107;
    -webkit-transition: box-shadow 200ms ease;
    transition: box-shadow 200ms ease;
    font-family: 'Source Code Pro', sans-serif;
    color: #333;
    font-weight: 900;

    &:hover {
      z-index: 1;
      border-style: solid;
      border-width: 0px;
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
    }
  }
}
