.police-brutality {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.collection-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .collection-list {
    flex-direction: column;
  }
}

@media screen and (min-width: 1920px) {
  .collection-list {
    justify-content: space-around;
  }
}