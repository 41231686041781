.navbar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  background-color: transparent;
  color: #fff;
}

.site-title {
  margin-bottom: 0px;
  font-family: 'Source Code Pro', sans-serif;
  font-weight: 200;
}

.brand {
  position: static;
  margin-top: auto;
  margin-bottom: auto;
  -webkit-transition: color 200ms ease, background-color 200ms ease;
  transition: color 200ms ease, background-color 200ms ease;
  color: #fff;

  &:hover {
    color: #ffc107;
  }

  &-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}

.nav-link {
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #fff;

  &:hover {
    color: #ffc107;
  }

  &.w--current {
    color: #5d5d5d;

    &:hover {
      color: #ffc107;
    }
  }
}

.nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-family: 'Source Code Pro', sans-serif;
  font-weight: 700;
}

.nav-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 90%;
}

.contact {
  &-container {
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 1;
    display: none;
    overflow: hidden;
    height: 100%;
    max-width: 100vw;
    margin-right: 0px;
    margin-bottom: 1rem;
    margin-left: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(51, 51, 51, 0.8);
    max-height: 0;
    transition: max-height 0.4s ease-out;

    &-shown {
      max-height: 4rem;
    }
  }

  &-form-row-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  &-wrapper {
    z-index: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 1.5rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-color: #333;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.2);
  }

  &-header {
    margin-right: 0.1em;
    margin-bottom: 0px;
    font-family: 'Source Code Pro', sans-serif;
    font-size: 4.5em;
    line-height: 1.3em;
    font-weight: 200;
  }

  &-description {
    max-width: 540px;
    margin-bottom: 0px;
    font-family: Inconsolata, monospace;
    font-size: 1.2em;
    line-height: 1.3em;
    font-weight: 400;
  }

  &-form-close {
    padding: 0.6rem 1rem;
    -webkit-transition: font-size 200ms ease, color 200ms ease;
    transition: font-size 200ms ease, color 200ms ease;
    cursor: pointer;

    &:hover {
      color: #ffc107;
      font-weight: 700;
    }
  }
}

.form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;

  &-block {
    width: 100%;
  }
}

.unstyled-button {
  background-color: unset;
}

.search {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin-bottom: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  &-container {
    overflow: hidden;
    width: 100%;
    height: 4rem;
    max-width: 90%;
    margin-right: auto;
    margin-bottom: 1rem;
    margin-left: auto;
    max-height: 0;
    transition: max-height 0.4s ease-out;

    &-shown {
      max-height: 4rem;
    }
  }

  &-bar {
    height: 100%;
    margin-bottom: 0px;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border: 0px none #000;
    border-radius: 0px;
    font-family: Inconsolata, monospace;
    font-size: 1.2em;
    line-height: 1.3em;
    font-weight: 400;
    text-transform: lowercase;

    &:focus {
      border: 0px none #000;
    }
  }

  &-button {
    position: relative;
    right: 9.8rem;
    z-index: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    border: 0px solid #333;
    background-color: #ffc107;
    -webkit-transition: box-shadow 200ms ease;
    transition: box-shadow 200ms ease;
    font-family: 'Source Code Pro', sans-serif;
    color: #333;
    font-size: 1em;
    font-weight: 900;
    text-transform: lowercase;

    &:hover {
      z-index: 1;
      border-style: solid;
      border-width: 0px;
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
    }

    &:last-child {
      margin-left: 1em;
    }
  }

  &-background {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100vw;
    height: 4rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    max-height: 0;
    transition: max-height 0.4s ease-out;

    &-shown {
      max-height: 4rem;
    }
  }
}

.search-button-nav {
  overflow: hidden;
  max-width: 0;
  padding-left: 0;
  padding-right: 0;
  transition: max-width, padding-left, padding-right 0.4s ease-out;

  &-shown {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 10rem;
  }
}

.submit-button {
  z-index: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  border: 0px solid #333;
  background-color: #ffc107;
  -webkit-transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
  font-family: 'Source Code Pro', sans-serif;
  color: #333;
  font-size: 1em;
  font-weight: 900;
  text-transform: lowercase;

  &:hover {
    z-index: 1;
    border-style: solid;
    border-width: 0px;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.65);
  }
}

.textarea {
  max-width: 570px;
  min-width: 570px;
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #ffc107;
  background-color: #333;
  -webkit-transition: border-width 200ms ease;
  transition: border-width 200ms ease;

  &:focus {
    border-style: none none solid;
    border-width: 1px 1px 4px;
    border-color: #000 #000 #ffc107;
  }
}

.text-field {
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #ffc107;
  background-color: #333;
  -webkit-transition: all 200ms ease, border-width 200ms ease;
  transition: all 200ms ease, border-width 200ms ease;

  &:focus {
    border-style: none none solid;
    border-bottom-width: 4px;
    border-bottom-color: #ffc107;
  }
}

@media screen and (min-width: 1280px) {
  .nav-link.w--current {
    color: #5d5d5d;
  }

  .brand-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .contact-header {
    margin-right: 0.1em;
  }
  .contact-description {
    font-size: 1.1em;
  }
}

@media screen and (min-width: 1440px) {
  .nav-container {
    max-width: 1500px;
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .search-container {
    max-width: 1500px;
    padding-right: 3rem;
    padding-left: 3rem;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}

@media screen and (min-width: 1920px) {
  .brand.w--current {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .nav-container {
    padding: 0.5rem 0rem;
  }

  .search-bar {
    padding-right: 0px;
    padding-left: 0px;
  }

  .search-container {
    margin-bottom: 0rem;
  }
}

@media screen and (max-width: 991px) {
  .menu-button.w--open {
    background-color: #333;
  }
  .nav-menu {
    background-color: #333;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.45);
  }
}

@media screen and (max-width: 479px) {
  .search-bar {
    font-size: 0.8em;
  }
  .search-button {
    right: 4.7rem;
    font-size: 0.8em;
  }
}
