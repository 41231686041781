@import 'assets/styles/variables';

body {
  background-color: #333;
  font-size: 16px;
  line-height: 22px;
}

a {
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #fff;
  text-decoration: none;
}

.inline-link {
  border-bottom: 1px solid #ffc107;
  -webkit-transition: border-width 200ms ease, color 200ms ease;
  transition: border-width 200ms ease, color 200ms ease;
  color: #fff;
  cursor: pointer;

  &:hover {
    border-bottom-width: 3px;
    color: #ffc107;
  }
}

.main-container {
  max-width: 90%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

// DEFAULT STYLES
// .App {
//   text-align: center;
// }

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }
