.footer {
  padding-top: 50px;
  padding-bottom: 50px;

  &.accent {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f1f1f1;
  }
}
